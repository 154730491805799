.Footer {
    margin: 0;
    padding: 0;
}

.Footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.Footer .footer-top {
    display: flex;
    justify-content: space-between !important;
}

.Footer .footer-top li {
    padding: 0.25rem 0;
}

.Footer a {
    text-decoration: none;
    color: inherit;
}

.Footer li {
    font-size: 0.8rem;
}

.Footer li a {
    border-bottom: 2px solid transparent;
}

.Footer li a:hover {
    border-bottom: 2px solid var(--bs-dark);
}