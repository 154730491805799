.Navbar {
  font-size: 0.9rem;
  height: 10vh;
}

.Navbar img {
  width: 200px;
}

.Navbar .navbar-toggler {
  border: none;
}

.Navbar .collapse {
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.5);
}
