.References {
  margin: 5rem 0;
  width: 100%;
  height: 250px;
}

.References .container {
  height: 100%;
}

.references-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.references-container a {
  width: 33%;
  padding: 0 1.5rem;
}

.ref-img-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.References img {
  max-height: 100%;
  max-width: 100%;
}
