.Gallery {
  height: 100vh;
  max-width: 100vw;
  background-color: #000;
  overflow: hidden;
}

.Gallery img {
  max-height: 100vh;
  max-width: 100%;
  margin: 0 auto;
}

.Gallery .gallery-items {
  display: flex;
  height: 100%;
  width: 85%;
  margin: 0 auto;
}

.Gallery button {
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 5rem;
  position: absolute;
  top: 50%;
}

#gallery-left {
  left: 0;
}

#gallery-right {
  right: 0;
}
