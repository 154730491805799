body {
  color: var(--bs-dark);
}

.center-self {
  text-align: center;
  align-self: center;
}

@keyframes next {
  0% {
    transform: translateX(3rem);
  }

  100% {
    transform: translateX(0);
  }
}
