.Card {
  margin: 0;
  padding: 0;
}

.Card .inner {
  margin: 1rem 0;
  padding: 0;
}

.pad {
  padding-right: 1.5rem !important;
}

.img-h {
  max-height: 50% !important;
}

.Card a {
  text-decoration: none;
  padding-right: 0.25rem;
}

.Card h1 {
  font-size: 1.2rem !important;
}

.Card p {
  font-size: 0.85rem;
}

.Card .card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  width: 90%;
}

.Card .card-content h1 {
  font-size: 1.5rem;
  margin-top: 1.5rem;
}
