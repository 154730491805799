.Section {
    margin: 3rem auto;
}

.Section h1 {
    text-transform: capitalize;
    font-size: 2rem;
}

.Section .section-items {
    display:flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    margin: 0 auto;
    padding: 5rem 0;
    height: 100%;
}

.Section .section-items a {
    width: fit-content;
}

.Section .section-items p {
    padding: 1rem 0;
}