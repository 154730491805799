.Header {
    position: relative;
    padding: 5rem 0;
    margin: 0 auto;
}

.Header .dots {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
}

.Header .dot {
    cursor: pointer;
    height: 0.5rem;
    width: 0.5rem;
    margin: 0 0.2rem;
    background-color: transparent;
    border: 1px solid var(--bs-dark);
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.Header .active, .Header .dot:hover {
    background-color: var(--bs-dark);
}

.prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-100%);
    width: auto;
    color: var(--bs-light);
    background-color: var(--bs-dark);
    font-size: 1.5rem;
    transition: 0.6s ease;
    user-select: none;
    padding: 0.5rem;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    border: none;
}

.prev {
    left: 1%;
}

.next {
    right: 1%;
}
  
.prev:hover, .next:hover {
    opacity: 0.7;
}