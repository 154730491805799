.Offers {
  height: 65vh;
  width: 100%;
}

.Offers .container {
  height: 100%;
}

.Offers .content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  flex-direction: column;
}
