.Slide {
  display: none;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.Slide a {
  width: fit-content;
}

.Slide h1 {
  font-size: 1.5rem;
}

.Slide p {
  padding: 0.75rem 0;
}

.Slide .slide-items {
  padding: 4rem 0;
}

.header-next {
  height: 300px;
  animation-timing-function: cubic-bezier(0.16, 1, 0.29, 0.99);
  animation-iteration-count: 1;
  animation-duration: 0.66s;
  animation-delay: 0s;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
  animation-name: next;
}
